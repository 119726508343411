export default {
    methods: {
        async getErrorCodeStrings(lang = 'en-US') {
            const locale = this.$cookie.get('wl_locale') || lang
            await this.$store.dispatch(
                'themes/language-string/getErrorStrings',
                locale
            )
        },
    },
}
